import { navigate } from "gatsby"

import React from "react"

import { RiCloseLine } from "react-icons/ri"

import Container from "../container/container"

import Logo from "../../images/logo/SVG/digitam-white.svg"

const Menu = ({ menuOpen, toggleMenu }) => {
        
    const menuIconOnClick = () => {
        const mainContent = document.getElementById("main-content") 
        mainContent.classList.remove("tilted");
        toggleMenu(!menuOpen);
    };

    const menuLinkOnClick = (key) => {
        document.getElementById("main-content").classList.remove("tilted");
        navigate(key);
    };

    return (
        <>
            <aside id="menu">
                <div className="menu-items">
                    <div className="menu-header">
                        <Container type="wide">
                            <div className="menu-close">
                                <RiCloseLine onClick={menuIconOnClick} />
                            </div>
                        </Container>
                        
                        
                    </div>
                    <div className="menu-content">
                        <Container type="wide">
                            {/* <div className="menu-content-header">
                            <img src={ Logo } alt=""/>
                            <p>| menu</p>
                        </div>      */}
                            <ul className="menu-links">
                                <li onClick={() => menuLinkOnClick("/")}>
                                    Home
                            </li>
                                <li onClick={() => menuLinkOnClick("/graphic-design")}>
                                    Graphic design
                            </li>
                                <li onClick={() => menuLinkOnClick("/web-development")}>Web development</li>
                                <li onClick={() => menuLinkOnClick("/#portfolio")}>Portfolio</li>
                                <li onClick={() => menuLinkOnClick("/contact")}><span className="highlight-blue">Contact</span> </li>
                            </ul>
                        </Container>
                        
                    </div>
                </div>
            </aside>
        </>
    )
}


export default Menu
