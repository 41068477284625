/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

import Header from "../images/social/headers/header.png"

function SEO({ description, lang, meta, title, cannonicalUrl }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s - ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `keywords`,
          content: `website maken, logo maken, webshop maken, webdesign, web development, graphic design, grafisch vormgever`,
        },
        {
          name: `language`,
          content: `NL`,
        },

        // Crawlers
        {
          name: `robots`,
          content: `index, follow`,
        },
        {
          name: `googlebot`,
          content: `index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1`,
        },
        {
          name: `bingbot`,
          content: `index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1`,
        },

        // og metatags
        {
          property: `og:locale`,
          content: `nl_NL`,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:url`,
          content: cannonicalUrl,
        },
        {
          property: `og:site_name`,
          content: `Digitam`,
        },
        {
          property: `og:image`,
          content: site.siteMetadata.siteUrl + Header,
        },
        {
          property: `og:image:secure_url`,
          content: site.siteMetadata.siteUrl + Header,
        },
        {
          property: `og:image:width`,
          content: `1920`,
        },
        {
          property: `og:image:height`,
          content: `1080`,
        },

        // Geo tags
        {
          name: `geo.region`,
          content: `NL-NB`,
        },
        {
          name: `geo.placename`,
          content: `Oosterhout (NB)`,
        },
        {
          name: `geo.position`,
          content: `51.6513472, 4.8396571`,
        },
        {
          name: `ICBM`,
          content: `51.6513472, 4.8396571`,
        },

        // Article modified
        {
          property: `article:modified_time`,
          content: `2020-07-30T10:00:00+00:00`,
        },

        // Twitter meta tags
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `twitter:image`,
          content: site.siteMetadata.siteUrl + Header,
        },
        {
          name: `twitter:site`,
          content: site.siteMetadata.siteUrl,
        },
      ].concat(meta)}
      link={[
        {
          rel: `canonical`,
          href: cannonicalUrl,
        },
      ]}
    >
      <link rel="preconnect" href="https://fonts.gstatic.com"></link>
      <link
        href="https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600&display=swap"
        rel="stylesheet"
      ></link>
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `NL`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
