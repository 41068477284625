import React, { useState } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Navbar from "./navbar/navbar"
import Menu from "./menu/menu"
import Footer from "./footer/footer"

const Layout = ({ children, footerDarkBackground }) => {
  // Navbar state
  const [menuOpen, toggleMenu] = useState(false);

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      
      <div id="main-content">
        <Navbar
          siteTitle={data.site.siteMetadata.title}
          menuOpen={menuOpen}
          toggleMenu={toggleMenu}
        />
        <main>{children}</main>
        <Footer darkBackground={footerDarkBackground} />
      </div>
      <Menu
        menuOpen={menuOpen}
        toggleMenu={toggleMenu}
      />
      
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  footerDarkBackground: PropTypes.bool,
}

Layout.defaultProps = {
  footerDarkBackground: true,
}

export default Layout
