import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import { RiInstagramLine, RiDribbbleLine, RiGithubLine, RiYoutubeLine, RiTwitterLine } from "react-icons/ri"

import Container from "../container/container"
import Icon from "../icon/icon"

import LogoLight from "../../images/logo/SVG/digitam-white.svg"
import LogoDark from "../../images/logo/SVG/digitam-black.svg"

const Footer = ({ darkBackground }) => {

    const openDropdown = (key) => {
        let col = document.getElementsByClassName("footer-col")[key]
        console.log(col.classList);
        if (col.classList.value.includes("opened")) {
            col.classList.remove("opened");
        } else {
            col.classList.add("opened");
        }
        
    }
    return (
        <footer className={darkBackground ? "footer-dark" : "footer-light"}>
            <Container type="default">
                <div className="footer-content">
                    <div className="footer-col" id="footer-digitam">
                        <p className="footer-title" onClick={() => openDropdown(0)} onKeyDown={() => openDropdown(0)}>Digitam</p>
                        <div className="footer-links">
                            <ul className="footer-links-col">
                                <li className="footer-link"><Link to="/">Home</Link></li>
                                <li className="footer-link"><Link to="/graphic-design">Graphic design</Link></li>
                                <li className="footer-link"><Link to="/web-development">Web development</Link></li>
                                <li className="footer-link"><Link to="/contact">Contact</Link></li>
                                <li className="footer-link"><Link to="/sitemap.xml">Sitemap</Link></li>
                            </ul>
                        </div>
                        
                    </div>
                    <div className="footer-col">
                        <p className="footer-title" onClick={() => openDropdown(1)} onKeyDown={() => openDropdown(1)}>Graphic design</p>
                        <div className="footer-links">
                            <ul className="footer-links-col extra-margin">
                                <li className="footer-link"><Link to="/graphic-design#logo">Logo</Link></li>
                                <li className="footer-link"><Link to="/graphic-design#branding-huisstijl">Branding &#38; Huisstijl</Link></li>
                                <li className="footer-link"><Link to="/graphic-design#poster-flyer">Poster &#38; Flyer</Link></li>
                                <li className="footer-link"><Link to="/graphic-design#visitekaartje">Visitekaartje</Link></li>
                                <li className="footer-link"><Link to="/graphic-design#brochure-folder">Brochure &#38; Folder</Link></li>
                            </ul>
                            <ul className="footer-links-col">
                                <li className="footer-link"><Link to="/graphic-design#kaartje">Kaartje</Link></li>
                                <li className="footer-link"><Link to="/graphic-design#briefpapier">Briefpapier</Link></li>
                                <li className="footer-link"><Link to="/graphic-design#speciale-wensen">Speciale wensen</Link></li>
                            </ul>
                        </div>
                        
                    </div>
                    <div className="footer-col">
                        <p className="footer-title" onClick={() => openDropdown(2)} onKeyDown={() => openDropdown(2)}>Web Development</p>
                        <div className="footer-links">
                            <ul className="footer-links-col">
                                <li className="footer-link">Website maken Oosterhout</li>
                                <li className="footer-link">Website maken Breda</li>
                                <li className="footer-link">Website maken Tilburg</li>
                                <li className="footer-link">Website maken Dongen</li>
                                <li className="footer-link">Website maken Gilze</li>
                            </ul>
                        </div>
                        
                    </div>
                    <div className="footer-contact">
                        <div className="footer-col">
                            <div className="footer-title" onClick={() => openDropdown(3)} onKeyDown={() => openDropdown(3)}>Contact</div>
                            <div className="footer-links">
                                <ul className="footer-links-col">
                                    <li className="footer-link">info@digitam.nl</li>
                                    <li className="footer-link">Merijntje Gijzenstraat 100</li>
                                    <li className="footer-link">4906 EB Oosterhout NB</li>
                                    <li className="footer-link">KVK: 77467949</li>
                                    <li className="footer-link">Btw-id: NL003196385B92</li>
                                </ul>
                            </div>
                            
                        </div>
                        <div className="footer-col">
                            <div className="footer-socials">
                                <div className="footer-title">Social</div>
                                <div className="footer-socials-icons">
                                    <a href="https://twitter.com/DigitamSocial" target="_blank" rel="noopener noreferrer nofollow" aria-label="Twitter" >
                                        <Icon icon={<RiTwitterLine />} mousePointer={true} />
                                    </a>
                                    <a href="https://instagram.com/DigitamSocial" target="_blank" rel="noopener noreferrer nofollow" aria-label="Instagram" >
                                        <Icon icon={<RiInstagramLine />} mousePointer={true} />
                                    </a>
                                    <Icon icon={<RiYoutubeLine />} mousePointer={true} />
                                    <a href="https://github.com/digitam" target="_blank" rel="noopener noreferrer nofollow" aria-label="Github" ><Icon icon={<RiGithubLine />} mousePointer={true} /></a>
                                    <Icon icon={<RiDribbbleLine />} mousePointer={true} />
                                </div>
                            </div>
                        </div>
                    </div>
                    

                </div>
                <div className="footer-bottom">
                    <div className="footer-brand">
                        {darkBackground && <img src={LogoLight} alt="digitam" />}
                        {!darkBackground && <img src={LogoDark} alt="digitam" />}
                    </div>
                    

                    <div className="footer-made-with">
                        Custom made by digitam with <a href="https://github.com/gatsbyjs/gatsby" target="_blank" rel="noopener noreferrer nofollow" aria-label="Gatsby" >GatsbyJS</a> and <a href="https://github.com/facebook/react" target="_blank" rel="noopener noreferrer nofollow" aria-label="React" >React</a>
                    </div>
                    <div className="footer-policies">Privacyverklaring &#8226; Algemene voorwaarden </div>
                    <div className="footer-copyright">Copyright &copy; {new Date().getFullYear()} Digitam All rights reserved</div>
                </div>

            </Container>


        </footer>
    );
}

Footer.propTypes = {
    darkBackground: PropTypes.bool,
}

Footer.defaultProps = {
    darkBackground: true,
}

export default Footer
