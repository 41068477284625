import PropTypes from "prop-types"
import React from "react"

const Container = ({ type, marginLeft, marginRight, children }) => {
    if (type === 'default') {
        return <div className="container">{children}</div>;
    } else if (type === 'narrow') {
        return <div className="container-narrow">{children}</div>;
    } else if (type === 'wide') {
        return <div className="container-wide">{children}</div>;
    } else if (type === 'custom') {
        return <div className="container-custom" style={{ marginLeft: marginLeft, marginRigth: marginRight}}>{children}</div>
    }
}

Container.propTypes = {
    type: PropTypes.string,
    children: PropTypes.any,
}

Container.defaultProps = {
    type: `default`,
}

export default Container
