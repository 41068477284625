import React from "react"

const Icon = ({ 
    icon, 
    textLeft, 
    textRight,
    capitalize,
    fontSize,
    mousePointer,
}) => {
    const style = {
        cursor: mousePointer ? "pointer" : "unset",
        fontSize: (fontSize !== undefined) ? fontSize : "unset",
        textTransform: capitalize ? "Uppercase" : "unset",
        fontWeight: capitalize ? "400" : "initial",
    }

    return (
        <div className="icon" style={style}>
            {textLeft !== undefined && <div className="icon-text-left">{textLeft}</div> }
            {icon}
            {textRight !== undefined && <div className="icon-text-right">{textRight}</div>}
        </div>
    )
}

export default Icon
