import { Link } from "gatsby"
import React from "react"

import { RiArrowDropDownLine, RiMenuLine} from "react-icons/ri"

import Container from "../container/container"
import Dropdown from "../dropdown/dropdown"
import Icon from "../icon/icon"
import Logo from "../../images/logo/SVG/digitam-grey.svg"

const Navbar = ({ siteTitle, menuOpen, toggleMenu }) => {
  const menuIconOnClick = () => {
      document.getElementById("main-content").classList.add("tilted")
      toggleMenu(!menuOpen);
  };

  return (
    <nav>
      <div className="nav-desktop">
        <Container type="default">
          <ul className="nav-links">
            <li className="nav-link">
              <Link to="/">
                <img src={Logo} alt="digitam" className="nav-brand" />
              </Link>
            </li>
            <li className="nav-link">
              <Link to="/graphic-design">
                Graphic design
            </Link>
            </li>
            <li className="nav-link">
              <Link to="/web-development">
                Web development
            </Link>{/*<RiArrowDropDownLine /> */}
              {/* <Dropdown items={["Item 1", "Item 2", "Item 3"]} /> */}
            </li>
            {/* <li className="nav-link">
            Overige werkzaamheden <RiArrowDropDownLine />
            <Dropdown items={["Item 1", "Item 2", "Item 3"]} />
          </li> */}
            <li className="nav-link">
              <Link to="/#portfolio">
                Portfolio
            </Link>
            </li>
            <li className="nav-link">

              <Link to="/contact"><span className="highlight-blue">
                Contact
                </span></Link>
            </li>
          </ul> 
        </Container>
               
      </div>
      <div className="nav-mobile">
        <Container type="wide">
          <ul className="nav-links">
            <li className="nav-link"><Link to="/"><img src={Logo} alt="digitam" className="nav-brand" /></Link></li>
            <li onClick={menuIconOnClick} className="nav-link">
              <Icon icon={<RiMenuLine />} />
            </li>
          </ul>
        </Container>
        
      </div>
    </nav>
  );
}
export default Navbar
